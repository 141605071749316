import React from 'react';
import Layout from '../components/Layout';

import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

import { LegalWrapper, Heading2, Heading3, Heading4 } from '../components/styled';

const Privacy = () => (
    <Layout>
        <Navbar limited />
        <LegalWrapper>
            <Heading2>Privacy Policy</Heading2>

            <div>
                <Heading3>1. An overview of data protection</Heading3>
                <Heading4>General</Heading4>
                <p>
                    The following gives a simple overview of what happens to your personal
                    information when you visit our website. Personal information is any data with
                    which you could be personally identified. Detailed information on the subject of
                    data protection can be found in our privacy policy found below.
                </p>
                <Heading4>Data collection on our website</Heading4>
                <p>Who is responsible for the data collection on this website?</p>
                <p>
                    The data collected on this website are processed by the website operator. The
                    operator’s contact details can be found in the website’s required legal notice.
                </p>
                <Heading4>How do we collect your data?</Heading4>
                <p>
                    Some data are collected when you provide it to us. This could, for example, be
                    data you enter on a contact form.
                </p>
                <p>
                    Other data are collected automatically by our IT systems when you visit the
                    website. These data are primarily technical data such as the browser and
                    operating system you are using or when you accessed the page. These data are
                    collected automatically as soon as you enter our website.
                </p>
                <Heading4>What do we use your data for?</Heading4>
                <p>
                    Part of the data is collected to ensure the proper functioning of the website.
                    Other data can be used to analyze how visitors use the site.
                </p>
                <Heading4>What rights do you have regarding your data?</Heading4>
                <p>
                    You always have the right to request information about your stored data, its
                    origin, its recipients, and the purpose of its collection at no charge. You also
                    have the right to request that it be corrected, blocked, or deleted. You can
                    contact us at any time using the address given in the legal notice if you have
                    further questions about the issue of privacy and data protection. You may also,
                    of course, file a complaint with the competent regulatory authorities.
                </p>
                <Heading4>Analytics and third-party tools</Heading4>
                <p>
                    When visiting our website, statistical analyses may be made of your surfing
                    behavior. This happens primarily using cookies and analytics. The analysis of
                    your surfing behavior is usually anonymous, i.e. we will not be able to identify
                    you from this data. You can object to this analysis or prevent it by not using
                    certain tools. Detailed information can be found in the following privacy
                    policy.
                </p>
                <p>
                    You can object to this analysis. We will inform you below about how to exercise
                    your options in this regard.
                </p>

                <Heading3>2. General information and mandatory information</Heading3>
                <Heading4>Data protection</Heading4>
                <p>
                    The operators of this website take the protection of your personal data very
                    seriously. We treat your personal data as confidential and in accordance with
                    the statutory data protection regulations and this privacy policy.
                </p>
                <p>
                    If you use this website, various pieces of personal data will be collected.
                    Personal information is any data with which you could be personally identified.
                    This privacy policy explains what information we collect and what we use it for.
                    It also explains how and for what purpose this happens.
                </p>
                <p>
                    Please note that data transmitted via the internet (e.g. via email
                    communication) may be subject to security breaches. Complete protection of your
                    data from third-party access is not possible.
                </p>
                <Heading4>Notice concerning the party responsible for this website</Heading4>
                <p>The party responsible for processing data on this website is:</p>
                <p>TAKKT GmbH</p>
                <p>Leopoldstraße 31</p>
                <p>80802 München</p>
                <p>Telephone: +49 89 20084119</p>
                <p>Email: hello@takkt.io</p>
                <p>
                    The responsible party is the natural or legal person who alone or jointly with
                    others decides on the purposes and means of processing personal data (names,
                    email addresses, etc.).
                </p>
                <Heading4>Revocation of your consent to the processing of your data</Heading4>
                <p>
                    Many data processing operations are only possible with your express consent. You
                    may revoke your consent at any time with future effect. An informal email making
                    this request is sufficient. The data processed before we receive your request
                    may still be legally processed.
                </p>
                <Heading4>Right to file complaints with regulatory authorities</Heading4>
                <p>
                    If there has been a breach of data protection legislation, the person affected
                    may file a complaint with the competent regulatory authorities. The competent
                    regulatory authority for matters related to data protection legislation is the
                    data protection officer of the German state in which our company is
                    headquartered. A list of data protection officers and their contact details can
                    be found at the following link:
                    https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html.
                </p>
                <Heading4>Right to data portability</Heading4>
                <p>
                    You have the right to have data which we process based on your consent or in
                    fulfillment of a contract automatically delivered to yourself or to a third
                    party in a standard, machine-readable format. If you require the direct transfer
                    of data to another responsible party, this will only be done to the extent
                    technically feasible.
                </p>
                <Heading4>SSL or TLS encryption</Heading4>
                <p>
                    This site uses SSL or TLS encryption for security reasons and for the protection
                    of the transmission of confidential content, such as the inquiries you send to
                    us as the site operator. You can recognize an encrypted connection in your
                    browser’s address line when it changes from “http://” to “https://” and the lock
                    icon is displayed in your browser’s address bar.
                </p>
                <p>
                    If SSL or TLS encryption is activated, the data you transfer to us cannot be
                    read by third parties.
                </p>
                <Heading4>Information, blocking, deletion</Heading4>
                <p>
                    As permitted by law, you have the right to be provided at any time with
                    information free of charge about any of your personal data that is stored as
                    well as its origin, the recipient and the purpose for which it has been
                    processed. You also have the right to have this data corrected, blocked or
                    deleted. You can contact us at any time using the address given in our legal
                    notice if you have further questions on the topic of personal data.
                </p>
                <Heading4>Opposition to promotional emails</Heading4>
                <p>
                    We hereby expressly prohibit the use of contact data published in the context of
                    website legal notice requirements with regard to sending promotional and
                    informational materials not expressly requested. The website operator reserves
                    the right to take specific legal action if unsolicited advertising material,
                    such as email spam, is received.
                </p>
                <Heading3>3. Data protection officer</Heading3>
                <Heading4>Statutory data protection officer</Heading4>
                <p>We have appointed a data protection officer for our company:</p>
                <p>Johannes Pecher</p>
                <p>TAKKT GmbH</p>
                <p>Leopoldstraße 31</p>
                <p> 80802 München</p>
                <p>Telephone: +49 89 20084119</p>
                <p>Email: hello@takkt.io</p>

                <Heading3>4. Data collection on our website</Heading3>
                <Heading4>Cookies</Heading4>
                <p>
                    Some of our web pages use cookies. Cookies do not harm your computer and do not
                    contain any viruses. Cookies help make our website more user-friendly,
                    efficient, and secure. Cookies are small text files that are stored on your
                    computer and saved by your browser.
                </p>
                <p>
                    Most of the cookies we use are so-called “session cookies.” They are
                    automatically deleted after your visit. Other cookies remain in your device’s
                    memory until you delete them. These cookies make it possible to recognize your
                    browser when you next visit the site.
                </p>
                <p>
                    You can configure your browser to inform you about the use of cookies so that
                    you can decide on a case-by-case basis whether to accept or reject a cookie.
                    Alternatively, your browser can be configured to automatically accept cookies
                    under certain conditions or to always reject them, or to automatically delete
                    cookies when closing your browser. Disabling cookies may limit the functionality
                    of this website.
                </p>
                <p>
                    Cookies which are necessary to allow electronic communications or to provide
                    certain functions you wish to use (such as the shopping cart) are stored
                    pursuant to Art. 6 paragraph 1, letter f of DSGVO. The website operator has a
                    legitimate interest in the storage of cookies to ensure an optimized service
                    provided free of technical errors. If other cookies (such as those used to
                    analyze your surfing behavior) are also stored, they will be treated separately
                    in this privacy policy.
                </p>
                <Heading4>Server log files</Heading4>
                <p>
                    The website provider automatically collects and stores information that your
                    browser automatically transmits to us in “server log files”. These are:
                </p>
                <ul>
                    <li>Browser type and browser version</li>
                </ul>
                <ul>
                    <li>Operating system used</li>
                </ul>
                <ul>
                    <li>Referrer URL</li>
                </ul>
                <ul>
                    <li>Host name of the accessing computer</li>
                </ul>
                <ul>
                    <li>Time of the server request</li>
                </ul>
                <ul>
                    <li>IP address</li>
                </ul>
                <p>These data will not be combined with data from other sources</p>
                <p>
                    The basis for data processing is Art. 6 (1) (f) DSGVO, which allows the
                    processing of data to fulfil a contract or for measures preliminary to a
                    contract.
                </p>
                <Heading4>Contact</Heading4>
                <p>
                    Should you send us questions via email, a contact form or any other form, we
                    will collect the data entered, including the contact details you provide, to
                    answer your question and any follow-up questions. We do not share this
                    information without your permission.
                </p>
                <p>
                    We will, therefore, process any data you provide only with your consent per Art.
                    6 (1)(a) DSGVO. You may revoke your consent at any time. An informal email
                    making this request is sufficient. The data processed before we receive your
                    request may still be legally processed.
                </p>
                <p>
                    We will retain the data you provide until you request its deletion, revoke your
                    consent for its storage, or the purpose for its storage no longer pertains (e.g.
                    after fulfilling your request). Any mandatory statutory provisions, especially
                    those regarding mandatory data retention periods, remain unaffected by this
                    provision.
                </p>
                <Heading4>Registration on this website</Heading4>
                <p>
                    You can register on our website in order to access additional functions offered
                    here. The input data will only be used for the purpose of using the respective
                    site or service for which you have registered. The mandatory information
                    requested during registration must be provided in full. Otherwise, we will
                    reject your registration.
                </p>
                <p>
                    To inform you about important changes such as those within the scope of our site
                    or technical changes, we will use the email address specified during
                    registration.
                </p>
                <p>
                    We will process the data provided during registration only based on your consent
                    per Art. 6 (1)(a) DSGVO. You may revoke your consent at any time with future
                    effect. An informal email making this request is sufficient. The data processed
                    before we receive your request may still be legally processed.
                </p>
                <p>
                    We will continue to store the data collected during registration for as long as
                    you remain registered on our website. Statutory retention periods remain
                    unaffected.
                </p>
                <Heading4>Processing of data</Heading4>
                <p>
                    We collect, process, and use personal data only insofar as it is necessary to
                    establish, or modify legal relationships with us (master data). This is done
                    based on Art. 6 (1) (b) DSGVO, which allows the processing of data to fulfill a
                    contract or for measures preliminary to a contract. We collect, process and use
                    your personal data when accessing our website (usage data) only to the extent
                    required to enable you to access our service or to bill you for the same.
                </p>
                <p>
                    Collected customer data shall be deleted after completion of the order or
                    termination of the business relationship. Legal retention periods remain
                    unaffected.
                </p>
                <Heading4>Data transmitted when entering into a contract</Heading4>
                <p>
                    We transmit personally identifiable data to third parties only to the extent
                    required to fulfill the terms of your contract, for example, to companies
                    entrusted to deliver goods to your location or banks entrusted to process your
                    payments. Your data will not be transmitted for any other purpose unless you
                    have given your express permission to do so. Your data will not be disclosed to
                    third parties for advertising purposes without your express consent.
                </p>
                <p>
                    The basis for data processing is Art. 6 (1) (b) DSGVO, which allows the
                    processing of data to fulfill a contract or for measures preliminary to a
                    contract.
                </p>
                <p>Data transferred when signing up for services and digital content</p>
                <p>
                    We transmit personally identifiable data to third parties only to the extent
                    required to fulfill the terms of your contract with us, for example, to banks
                    entrusted to process your payments.
                </p>
                <p>
                    Your data will not be transmitted for any other purpose unless you have given
                    your express permission to do so. Your data will not be disclosed to third
                    parties for advertising purposes without your express consent.
                </p>
                <p>
                    The basis for data processing is Art. 6 (1) (b) DSGVO, which allows the
                    processing of data to fulfill a contract or for measures preliminary to a
                    contract.
                </p>
                <Heading3>5. Analytics and advertising</Heading3>
                <Heading4>Google Analytics</Heading4>
                <p>
                    This website uses Google Analytics, a web analytics service. It is operated by
                    Google Inc., 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA.
                </p>
                <p>
                    Google Analytics uses so-called &quot;cookies&quot;. These are text files that
                    are stored on your computer and that allow an analysis of the use of the website
                    by you. The information generated by the cookie about your use of this website
                    is usually transmitted to a Google server in the USA and stored there.
                </p>
                <p>
                    Google Analytics cookies are stored based on Art. 6 (1) (f) DSGVO. The website
                    operator has a legitimate interest in analyzing user behavior to optimize both
                    its website and its advertising.
                </p>
                <Heading4>Browser plugin</Heading4>
                <p>
                    You can prevent these cookies being stored by selecting the appropriate settings
                    in your browser. However, we wish to point out that doing so may mean you will
                    not be able to enjoy the full functionality of this website. You can also
                    prevent the data generated by cookies about your use of the website (incl. your
                    IP address) from being passed to Google, and the processing of these data by
                    Google, by downloading and installing the browser plugin available at the
                    following link: https://tools.google.com/dlpage/gaoptout?hl=en
                </p>
                <Heading4>Objecting to the collection of data</Heading4>
                <p>
                    You can prevent the collection of your data by Google Analytics by defining your
                    cookie settings. An opt-out cookie will be set to prevent your data from being
                    collected on future visits to this site.
                </p>
                <Heading4>Disable Google Analytics</Heading4>
                <p>
                    For more information about how Google Analytics handles user data, see
                    Google&#x27;s privacy policy:{' '}
                    <a href='https://support.google.com/analytics/answer/6004245?hl=en'>
                        https://support.google.com/analytics/answer/6004245?hl=en
                    </a>
                </p>
            </div>
        </LegalWrapper>
        <Footer relative />
    </Layout>
);

export default Privacy;
